<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo
        :width="80"
        :height="80"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ t('Không tìm thấy trang') }} 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          {{ t('Lỗi! 😖 Trang bạn yêu cầu không tồn tại.') }}
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          {{ t('Quay về trang chủ') }}
        </b-button>

        <!-- image -->
        <b-img
          fluid
          src="@/assets/images/pages/error.svg"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue';
import Logo from '@/layouts/components/Logo.vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    Logo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {};
  },
  computed: {},
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
